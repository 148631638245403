

  
     .SliderMain{
      height: 100%;
        position: relative;
        z-index: 0;
     }

   .SliderMain .swiper {

    width: 110%;
    height: 100%;
    margin-left: -3.5rem;
    margin-bottom: 15rem;
  }
  
  .SliderMain .swiper-slide {
    
    text-align: center;
    font-size: 18px;
    background:#fff;
  
    height: 300%;
  width: 110%; 
    /* Center slide text vertically  */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
 .mySwiper2 .swiper-slide img {
  
    display: block;
    width: 100%;
    height: 300%;
    object-fit: cover;
  }


  .sl-slider{
    position: relative; 
    
  }

  .sl-opacity{
    position: absolute;
    width: 100dvw;
    height: 100dvh;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.2;
  }
  .slider-p{
    position: absolute;
    width: 35rem;
    /* margin: auto 0; */
    font-size: 28px;
    top:33% ;
    left: 25%;
    z-index: 2;
   
    /* animation-timing-function: fade; */
    
  }
  
  .slider-s2{
    position: absolute;
    top:30% ;
    left: 23%;
    width: 40rem;
    height: 10rem;
    background-color: rgba(240, 248, 255, 0.9);
    border-radius: 5rem; 
  }

  
 

  @media screen and (max-width: 768px) {
    .SliderMain{
      width: 91dvw;
      margin-left: 0.3rem;
      height: 50dvh;
      position: relative;
      margin-top: 9rem;
      transform: scale(1, 2.2);
     /*  z-index: 0; */
   }

 .SliderMain .swiper {
  margin: 0 auto;
  margin-top: -2rem;
  width: 100dvw;
  min-height: 35dvh;
  margin-left: -4rem; 
  /* transform: scale(1, 2.5); */
  /* margin-bottom: 150rem; */
}
.SliderMain .mySwiper2{
  height: 20dvh;
}
.SliderMain .swiper-slide {
      height: 100%; /* Adjust slide height for mobile */

  text-align: center;
  font-size: 8px;
  background: #fff;

  /* height: 300%; */
width: 110%; 
  /* Center slide text vertically  */
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.mySwiper2 .swiper-slide img {
  
  display: block;
  width: 100%;
  height: 300%;
  object-fit: cover;
}


.sl-slider{
  position: relative; 
}
.sl-opacity{
  display: none;
}
.slider-p{
  /* width: 60dvw;
transform: scale(0.9);
  font-size: 12px;
  font-style: bold;
  top: 30%;
  left: 18%;
margin-top: -1rem;
font-family: "Gill Sans", sans-serif;
color: white;
letter-spacing: 0.09em; */
  /* animation-timing-function: fade; */
  display: none;
}

.slider-s2{
  /* position: absolute;
  width: 79%;
  margin: auto 0;
  font-size: 28px;
  height: 65%;
  margin-left: -2.4rem;
  margin-top: -0.9rem;
  border-radius: 30px;
  background-color: rgba(2, 2, 2, 0.7);
  letter-spacing: 29px; */

  display: none;
  /* z-index: 2; */
}
.sl-opacity{
  display: none;
  border-radius: 1rem;
  
}
  }
