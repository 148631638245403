.assessments{
    width: 100dvw;
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    background-color: rgb(9, 101, 70);
    margin-left: -3.5rem;
    margin-top: -6.3rem;
}
.assessments h2{
  text-align: center;
  width: 40dvw;
  margin: auto;
  font-size: 56px;
  margin-top: 7rem;
  padding-bottom: 1rem;
  font-family: "Gill Sans", sans-serif;
  color: aquamarine;
  border-bottom: 6px double black;
}
.assessments h2 span{
  text-align: center;
  font-size: 56px;
  margin-top: 7rem;
  padding-bottom: 1rem;
  font-family: "Gill Sans", sans-serif;
  /* color: aquamarine; */
  color: aquamarine; 
}


.assessments-container{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    
    align-items: center;
    margin-top: 3rem;
 }
 .ass-one{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-basis: 200;
  gap: 1rem;
  margin-right: 8rem;
  margin-bottom: 2rem;
 }
/*.assessments-container .ass-row{
    margin-bottom: 1rem;

}
.assessments-container .ass-one{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 45dvw;
    margin-top: 1rem;

} */
.ass-one button{
width: 15rem;
background: #fca61f;
border-radius: 0.5rem;
color: black;
align-self: center;
font-size: 30px;
}

.ass-one .ass-btn:hover{
/* background-color: rgb(42, 165, 134); */
background-color: rgb(254, 141, 3);
list-style: none;
text-decoration: none;
}


.assessments-container img{
    width: 390px;
    height: 60dvh;
    border: 5px double green;
}
 

@keyframes myAnim {
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 0.2;
    }
  
    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width : 768px) {
    /* .assessments{
      
    } */
    .assessments h2{
      font-size: 34px;
      text-align: center;
      margin-left: 3rem;
      /* margin-left: -0.1rem; */
    }
    .ass-one{
      transform: scale(0.8,1);
      margin-left: -1rem;
    }
  }