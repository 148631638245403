.frozen{
    width: 100dvw;
    min-height: 420svh;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    background-color: rgb(171, 30, 30);
    margin-left: -3.5rem;
    margin-top: -6.3rem;
    
}

.fruitsContainer{
	width: 100dvw;
    min-height: 150svh;
    display: flex;
    /* gap: 3rem; */
    justify-content: space-around;
    flex-direction: column;
    margin-left: -0.5rem;
    margin-top: 5rem;
    /* margin-bottom: 20rem; */
    /* background-color: aqua; */
    /* flex-wrap: wrap; */
}
.fruitsContainer .fruitsHeader{
	text-align: center;
	font-size: 56px;
	padding-bottom: 2rem;
	font-family: "Gill Sans", sans-serif;
	/* color: #FFC436; */
	color: #ffe203;
  
   }
#fruit-type{
	display: flex;
	width: 100vw;
	height: 100svh;
	flex-wrap: wrap;
	/* flex-direction: column; */
}

.fruits-rows{
    width: 100dvw;
    height: 30svh;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    align-items: center;
    /* gap: 3.5rem; */
    justify-content: center;
    flex-grow: 1;
    flex-basis: 200;
 }
 .fruits-row{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    flex-grow: 1;
    flex-basis: 200;
	margin-bottom: 1rem;
    /* margin-bottom: 0.5rem; */
 }

 .fruits-row #fruitsImage{
    width: 350px;
    height: 400px;
    /* border-radius: 100%; */
    border: 5px solid rgba(183, 180, 175, 0.4);
	
 }
 .fruits-row:hover {
/* display: block; */
cursor: pointer;

 }
 .fruits-row:hover #fruitsImage {
	/* display: block; */
opacity: 0.5 ;
background-color: black;
	 }
 .fruits-row:hover #fruitsName{
	display: block;
	animation-name: zoomInDown;
	animation-duration: 1s;
	/* align-items: center; */
 }
 .fruits-row #fruitsName{
	position: absolute;
	margin-top: 10%;
    font-size: 46px;
    padding: 0;
    font-family: "Gill Sans", sans-serif;
    color: #FFC436; 
	
	display: none;

 }

 #fruitsModal{
	width: 500px;
	height: 100svh;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: -1rem;
	/* padding-bottom: 5rem; */
  }
  .ModalH{
	font-size: 32px;
	margin-top: -0.25rem;
	/* color: v; */
  }
  
  #fruitsModal #ModalImg{
	width: 450px;
	height: 450px;
  }
  #fruitsModal #ModalBtn{
	background-color: red;
	padding: 0 1rem;
	border-radius: 1rem;
	color: aliceblue;
  }
  .ModalP{
	margin-left: 1rem;
	width: 95%;
	/* max-height: 100px; */
  }




 .zoomInDown {
	-webkit-animation-name: zoomInDown;
	animation-name: zoomInDown;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	}
	@-webkit-keyframes zoomInDown {
	0% {
	opacity: 0;
	-webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
	transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
	-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}
	60% {
	opacity: 1;
	-webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
	transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
	-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
	}
	@keyframes zoomInDown {
	0% {
	opacity: 0;
	-webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
	transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
	-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}
	60% {
	opacity: 1;
	-webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
	transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
	-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
	} 

	@media screen and (max-width : 768px) {
		#frozen{
			width: 100dvw;
			min-height: 365vh;
			display: flex;
			flex-direction: column;
			/* flex-wrap: wrap; */
			background-color: rgb(171, 30, 30);
			margin-left: -3.5rem;
			margin-top: -6.1rem;

			/* background-image: url("../../img/slider5.webp");
			background-repeat: no-repeat;
			background-attachment: fixed; */
			
		}
		#frozenContainer #frozenHeader{
			margin-top: 1.2rem;
		}
		#frozenContainer{
			margin-left: -0.1rem;
		}
		.fruits-row #fruitsImage{
			width: 280px;
			height: 300px;
			/* border-radius: 100%; */
			border: 5px solid rgba(183, 180, 175, 0.4);
			
		 }
		 
 #fruitsModal{
	width: 270px;
	height: 80vh;
	margin: 0 auto;
	margin-left: 0.4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 2rem;
	/* padding-bottom: 5rem; */
  }
  #fruitsModal #ModalImg{
	width: 200px;
	height: 250px;
  }
  .fruits-row #fruitsName{

    color: red; 
	

 }
	 
	}