#About{
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: -7rem;
   
}
.about-Mheader{
   text-align: center;
   margin-bottom: 2rem;
   
}
.about-Mheader .about-h{
   font-size: 50px;
   color: var(--orange);
   border-bottom: 2px solid var(--orange);
}
.about-Mheader .about-p {
   font-size: 30px;
   font-family: "Gill Sans", sans-serif;

}
.about-container{
   display: flex;
   flex-wrap: wrap;
   /* flex-direction: column; */
   /* align-items: center; */
   width: 90dvw;
   justify-content: space-around;
   font-family: "Gill Sans", sans-serif;

gap: 2.5rem;
}

.about{
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   flex-basis: 200;
   width: 18dvw;
   align-items: center;
   font-family: "Gill Sans", sans-serif;
   border-width: 4px;
  border-style: double;
  border-image: linear-gradient(to right, rgb(100, 100, 186), #5A96E3) 1;
}
.about .about-header{
   font-size: 38px;
   color: var(--orange);
   font-family: "Gill Sans", sans-serif;
   border-bottom: 2px solid green  ;
}
.about-desc{
   text-align: center;
   font-size: 18px;
   font-style: bold;
   font-family: "Gill Sans", sans-serif;
}
@media screen and (max-width : 768px) {
   #About{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -2rem;
      
   }
   .about-Mheader{
      text-align: center;
      margin-bottom: 2rem;
   }
   .about-Mheader .about-h{
      font-size: 50px;
      border-bottom: 2px solid var(--orange);
      /* font-family: "Gill Sans", sans-serif; */
   }
   .about-Mheader .about-p ,span{
      font-size: 18px;
      font-family: "Gill Sans", sans-serif;
   }
   .about-container{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      width: 90dvw;
      justify-content: space-around;
      font-family: "Gill Sans", sans-serif;
   
   gap: 2.5rem;
   }
   
   .about{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 200;
      width: 75dvw;
      align-items: center;
      font-family: "Gill Sans", sans-serif;
   }
   .about .about-header{
      font-size: 38px;
      color: var(--orange);
      margin-bottom: 2rem;
      font-family: "Gill Sans", sans-serif;
   }
   .about-desc{
      text-align: center;
      font-size: 16px;
      font-family: "Gill Sans", sans-serif;
   }
}