.orange{
    min-height: 100dvh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	width: 100dvw;
	margin-left: -4rem;
	margin-top: -6.3rem;

/* background-image: url("../../img/oneBg.jpg"); */
/* background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; */ 
}
.orange .main-fr-head{
    text-align: center;
    font-size: 60px;
    margin-top: -10rem;
    color: azure;
    width: 250px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.414);
}
.orange .fr-main-bg{
    width: 98dvw;
    height: 40dvh;
	margin-top: 6.1rem;
    /* margin-left: -1rem; */
    /* margin-top: 2rem; */
}

.orange-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
	margin-left: 3rem;
    /* background-color: rgba(128, 128, 128, 0.189); */
}
.orange-type{
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: start;
    gap: 1rem;
    margin-bottom: 3rem;
}
.orange-type .orange-title{
    font-size: 60px;
    animation: myAnim 1s ease 0s 1 normal forwards;
	font-family: "Gill Sans", sans-serif;
	color: white;

}
.orange-descs{
	display: flex;
	flex-direction: column;
	gap: 1rem;
	
}
.orange-desc{
	display: flex;
	/* justify-content: center; */
	/* align-items: center; */
	gap: 1rem;
	font-family: "Gill Sans", sans-serif;
	

}
.f-desc-h{
	font-size: 32px;
	width: 150px;
	color: white;
}
.f-desc-p{
	font-size: 26px;
	
}
.orange-type .orange-desc{
    font-size: 32px;
    animation: myAnim2 1s ease 0s 1 normal forwards;
}

.orange-images{
display: flex;
flex-wrap: wrap;
gap: 2rem;
margin-top: 1.5rem;
margin-left: 2rem;
}

.orange-images img{
    width: 400px;
    height: 250px;
    flex-grow: 1;
    flex-basis: 200;
    
}
.orange-images>:nth-child(1){
    animation: myAnim 2s ease 0s 1 normal forwards;
}
.orange-images>:nth-child(3){
    animation: myAnim2 2s ease 0s 1 normal forwards;
}
.orange-images>:nth-child(2){
    animation: myAnim2 1.5s ease 0s 1 normal forwards;
}
@keyframes myAnim {
	0% {
		animation-timing-function: ease-in;
		opacity: 1;
		transform: translateX(-48px);
	}

	24% {
		opacity: 1;
	}

	40% {
		animation-timing-function: ease-in;
		transform: translateX(-26px);
	}

	65% {
		animation-timing-function: ease-in;
		transform: translateX(-13px);
	}

	82% {
		animation-timing-function: ease-in;
		transform: translateX(-6.5px);
	}

	93% {
		animation-timing-function: ease-in;
		transform: translateX(-4px);
	}

	25%,
	55%,
	75%,
	87%,
	98% {
		animation-timing-function: ease-out;
		transform: translateX(0px);
	}

	100% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateX(0px);
	}
}

@keyframes myAnim2 {
	0% {
		animation-timing-function: ease-in;
		opacity: 1;
		transform: translateX(48px);
	}

	24% {
		opacity: 1;
	}

	40% {
		animation-timing-function: ease-in;
		transform: translateX(26px);
	}

	65% {
		animation-timing-function: ease-in;
		transform: translateX(13px);
	}

	82% {
		animation-timing-function: ease-in;
		transform: translateX(6.5px);
	}

	93% {
		animation-timing-function: ease-in;
		transform: translateX(4px);
	}

	25%,
	55%,
	75%,
	87%,
	98% {
		animation-timing-function: ease-out;
		transform: translateX(0px);
	}

	100% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateX(0px);
	}
}
@media screen and (max-width : 768px) {
	.orange{
		width: 105dvw;
	}
	.main-fr-head{
		margin-left: 1rem;
	}
	.orange-title{
		margin-left: 6rem;
		text-align: center;
	}
	.orange-container{
		flex-direction: column;
		display: flex;
		margin-left: -2rem;
	}
	.orange-desc{
		flex-direction: column;
		display: flex;
		margin-left: 3rem;
		width: 90dvw;
		gap: 0.1rem;
	}
	.orange-images{
		width: 85dvw;
		margin-left: 3rem;
	}
	.orange-images>:nth-child(1) {
		width: 85dvw;
		/* margin-left: 1rem; */
	}
	.f-desc-h{
		text-align: center;
		width: 80dvw;
		
	}
	.f-desc-p{
		text-align: center;
	}
}