


.footer{
    width: 100vw;
    height: 22rem;
    margin-left: -5vw;
    background-color: #0F0F0F;
    color: white;
    padding-bottom: 1.5rem;
}

.footer-container{
    display: flex;
    width: 90%;
    /* flex-direction: column; */
    justify-content: space-around;
    margin-left: 3%;
    gap: 5rem;
    padding-bottom: 3.5rem;
    
}
.footer-container .f-left1{
flex: 3;
}
.header{
    font-size: 32px;
    margin-bottom: 0.7rem;
    padding-top: 1.5rem;
    border-bottom: 1px solid var(--orange);
    font-family: "Gill Sans", sans-serif;
    color: #fca61f;
    /* color: #fca61f; */
}
/* .f-left1 .header{
    margin-top: 2rem;
} */
.f-left1 .desc{
    display: flex;
    /* align-items:  ; */
    gap: 0.5rem;
    
}
.f-left1 .location{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
}

.footer-container .f-left2{
    flex: 3;
}
.footer-container .f-left2 ul{
    list-style-type: none;
    margin-left: -1.5rem;
}
.footer-container .f-left2 ul li{
        padding-bottom: 0.7rem;
        font-size: 22px;
        font-family: "Gill Sans", sans-serif;
}
.footer-container .f-left2 .left2-row{
    display: flex;
    gap: 0.5rem;
}
.footer-container .f-right1{
    flex: 3;
}
.footer-container .f-right1 ul{
list-style-type: none;
margin-left: -2rem;

}
.footer-container .f-right1 ul li{
    padding-bottom: 0.7rem;
    color: aliceblue;
    /* color: white; */
}
.footer-container .f-right1 ul li:hover{
    padding-bottom: 0.9rem;
    color: #fca61f;
    cursor: pointer;
    font-family: "Gill Sans", sans-serif;
    /* border: 1px solid var(--orange); */
    
}

.footer-container .f-left2{
    flex: 3;
}


.f-right2{
    display: flex;
    flex-direction: column;
}

.f-right2 .photo{
    display: flex;
    gap: 1rem;
}
.f-right2 .photo img{
    width: 7rem;
    height: 7rem;
    margin-bottom: 1rem;
}

.footer .footer-end{
    display: flex;
    justify-content: space-around;
    width: 100vw;
    height: 30rem;
    background-color: #fca61f;
        color: black;
    margin-top: -3rem;

}
.end-left ,.end-right{
    margin-top: 0.1rem;
}
.end-right #teamName{
    margin-bottom: 0.2rem;
    font-size: 22px;
    color: greenyellow;
    margin-left: 0.5rem;
    font-family: "Gill Sans", sans-serif;
}

@media screen and (max-width: 480px) {

    .footer{
        width: 105dvw;
        height: 54.8rem;
        margin-left: -22vw;
        background-color: #0F0F0F;
        color: white;
        padding-bottom: 1.5rem;
        
        position: relative;
    }
    .footer-container{
        display: flex;
        flex-direction: column;
        width: 90%;
        /* flex-direction: column; */
        /* justify-content: space-around; */
        margin-left: 5%;
        gap: 0rem;
        padding-bottom: 0;
        
    }

    .footer-container .f-left1{
        flex: 3;
        }
        .header{
            font-size: 20px;
            margin-bottom: 0.3rem;
            padding-top: 1.5rem;
            border-bottom: 1px solid var(--orange);
            width: 5rem;
            font-size: 26px;
            color: #fca61f;
        }
        /* .f-left1 .header{
            margin-top: 2rem;
        } */
        .f-left1 .desc{
            display: flex;
            /* align-items:  ; */
            gap: 0.5rem;
            max-width: 90%;
          
        }
        .f-left1 .desc>:nth-child(2){
            font-size: 20px;
            font-family: "Gill Sans", sans-serif;
        }
        
        .f-left1 .location{
            display: flex;
            align-items: center;
            max-width: 90%;
            gap: 1rem;
        }
        .f-left1 .location>:nth-child(2){
            font-size:21px;
            font-family: "Gill Sans", sans-serif;
        }
        .footer-container .f-left2 ul li{
            padding-bottom: 0.5rem;
            font-size: 22px;
            font-family: "Gill Sans", sans-serif;
    }
    .left2-row{
        margin-left: -0.1rem;
    }
    
    .f-right1-body{
        margin-left: 1rem;
    }

    .f-right1 ul{
        margin-left: 1.5rem;
    }
    .f-right1 ul li{
        font-size:22px;
        margin-bottom: -0.5rem ;
        margin-left: -0.5rem;
        font-family: "Gill Sans", sans-serif;
    }
    .f-right2 .header{
        margin-bottom: 1rem;
        font-size: 26px;
    }
    .f-right2 .photos{
        transform: scale(0.8);
        display: flex;
        gap: 1.2rem;
        
    }
    .f-right2 .photo{
        gap: 1rem;
    }
    .f-right2 .photo img{
       width: 80px;
       height: 70px;
       margin-left: -2rem;
       margin-right: 1.5rem;
        /* flex-direction: column; */
    }

    .footer .footer-end{
        display: flex;
        flex-direction: column;
        /* justify-content: ; */
        width: 105dvw;
        height: 3rem;
        background-color: #fca61f;
        color: black;
        font-family: "Gill Sans", sans-serif;
        
        
        /* margin-top: -3.5rem; */
    
    }
    .footer-end{
        position: absolute;
        bottom: -10px;
    }
   
    .end-left,.end-right{
        transform: scale(0.8);
        font-style: bolder;
        text-align: center;
        font-family: "Gill Sans", sans-serif;
        font-size: 23px;
        color: black;
        
    }
    .end-right{
        margin-bottom: 0.2rem;
    }
    .end-right #teamName{
        margin-bottom: 0.2rem;
        font-size: 22px;
        color: white;
        margin-left: 0.5rem;
        font-family: "Gill Sans", sans-serif;

    }
} 