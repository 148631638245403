:root
{
  --yellow: #F5C32C;
    --orange : #0174BE;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App{
    padding: 0.5rem 3.5rem;
    overflow: hidden;
    color: var(--black); 
    /* background-color: wheat;  */
    background-color: white;  
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
.button{
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
  background: linear-gradient(180deg , #fdc50f 26.71% , #FFC436 99.36%);
  box-shadow: 0 20px 24px 3px rgba(251, 161, 40, 0.42);
}

.button:hover{
  color: var(--orange);
  background: white;
  border: 4px solid var(--orange);
  cursor: pointer;
}
 /* .blur{
    background:#C1F5FF;
    top : 7rem;
    height:11rem;
    width:21rem;
    
  } */

  .stroke-text{
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(13, 2, 2);
  }
  
  /* .blur{
    background: rgba(253, 120, 43, 0.7);
    border-radius: 50%;
    position: absolute;
    z-index: -10;
    filter: blur(150px);
  } */

  