.Testimonials{
    display: flex;
    gap: 1rem;
    padding: 1rem 2rem;
    margin-bottom: 8rem;
    height: 50vh;

}

.left-t{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 2rem;
    color: black;
    text-transform: uppercase;
    font-family: "Gill Sans", sans-serif;
}

.left-t>:nth-child(1){
    font-weight: bold;
    font-size: 3rem;
    color: var(--orange);
    margin-top: 3rem;
    margin-bottom: -2rem;
    font-family: "Gill Sans", sans-serif;
}
.left-t>:nth-child(2),
.left-t>:nth-child(3)
{
    font-weight: bold;
    font-size: 3rem;
}

.left-t>:nth-child(4){
    /* max-height: 100px; */
    min-height: 120px;
    text-align: justify;
    letter-spacing: 0.3px;
    line-height: 35px;
    margin-bottom: 2rem;
    text-transform: capitalize;
    font-family: "Gill Sans", sans-serif;
    white-space: normal;
}
.left-t>:nth-child(5) , .veg-name{
font-size: 2rem;
margin-top: -2rem;
}

.right-t{
    flex: 1;
    position: relative;
    font-family: "Gill Sans", sans-serif;
}

.right-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 4rem;
    top: 2rem;
}
.right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    right: 10rem;
    top: 1rem;
    border: 1px solid var(--orange);
    font-size:"3rem"
    
}
.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 20rem;
    right: 6rem;
    top: 3rem;
    background-color: var(--orange);
    opacity: 1;
}
.arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    left:  3rem;
    bottom: 0rem;
}

.arrows>img{
    width: 2rem;
}
.first-img{
    left: 1rem;
}
.first-arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    left:  30rem;
    bottom: 0rem;
}
.first-r{
left: -12rem;
}

@media screen and  (max-width : 768px){
    .Testimonials{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        /* margin-bottom: 22rem; */
        margin-top: -20rem;
        width: 100dvw;
        height: 120svh;
        margin-left: -3.5rem;
        margin-bottom: 10rem;
    }
    .up-t{
        margin-bottom: 15rem;
    }
    /* .up-d{
        margin-top: -19rem;
    } */
    .left-t{
        gap: 0.8rem;
        margin-left: -1.4rem;
        margin-bottom: 1rem;
        /* transform: scale(1.1); */
        
    }
    .left-t>:nth-child(1){
        /* font-size: small; */
        font-size: 36px;
        /* text-align: center; */
        margin-left: -2.5rem;
    }
    .left-t>:nth-child(4){
        font-size: small;
        line-height: 1rem;
        max-height: 100px;
        margin-bottom:2.5rem;
        text-align: center;
        color: black;
        font-style: bold;
        font-size: 19px;
        width: 65%;
        margin-left: 2rem;
        text-transform: none;
        white-space: normal;
    }
    .left-t>:nth-child(5){
        text-align: center;
        font-size: 14px;
        margin-top: -2rem;
    }
    .arrows{
        display: flex;
        gap: 1rem;
        position: absolute;
        left:  6.5rem;
        bottom: 10rem;
        /* z-index: 9999; */
    }
    .first-r{
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        position: absolute;
        top:12rem;
        width: 80dvw; 
        margin-left: 12rem;
        height: 100svh;
        transform: scale(0.9);
        
        /* align-self: center; */
    }
    .right-t>img{
        position: absolute; 
        left:  1rem;
        transform: scale(0.5);
        height: 60%;
        width: 90dvw;
    }
     .right-t>:nth-child(2){
        left: -0.7rem;
    }
    .right-t>:nth-child(1){
        left: -2.4rem;
    }
    .right-t>:nth-child(3){
        /* left: -0.7rem; */
        margin-top: 2rem;
        height: 55%;
    }
    .first-r>:nth-child(3){
        /* left: -0.7rem; */
        
        margin-top: 2rem;
        height: 50%;
    }
    #fruitNaming{
        margin-left: -2rem;
    }
    .veg-name{
        text-align: center;
        font-size: 30px;
        /* margin-left: 0.5rem; */
        padding-bottom: 0.5;
    }
    /* .first-arrows{
        margin-top: 1.5rem;
    } */
    
    
    .second-r{
        display: flex;
        flex-direction: column;
        margin-top: -10rem;
        left: -2.2rem;
        transform: scale(0.9);
        height: 100svh;
        width: 80dvw;
        margin-top: -11rem;
       
    }
    .second-r>img{
        position: absolute; 
        left:  1rem;
        transform: scale(0.5);
        height: 55%;
        width: 90dvw;
    }
    .down-r{
        margin-top: -22rem;
        margin-left: 0.3rem;
    }

    .second-r .arrows{
        display: flex;
        gap: 1rem;
        position: absolute;
        left:  6.5rem;
        bottom: 6.5rem;
        z-index: 100000;
    }

    .second-l{
        height: 100%;
    }
    
    .second-l>:nth-child(1){
        font-size: 35px;
        /* margin-left: -2rem; */
    }
    .second-l>:nth-child(4){
        max-height: 75px;
        line-height: 1.5;
        margin-left: 1.2rem;
        text-align: center;
        width: 80%;
        /* letter-spacing: 0; */
    }
    .second-l>:nth-child(5){
        font-size: 30px;
        margin-top: 1rem;
    }
  
   
    
    

} 