.contact-form{
    display: flex;
    padding: 0 3rem;
    margin-top: 4rem;
    height: 25rem;
}

.w-left{
    display: flex;
    flex: 1;
    flex-direction: column;
}
.c-right{
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.c-right>form{
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    /* justify-content: center; */
    
}
.awesome{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.awesome span{
    font-size: 48px;
}
.awesome #contact-contact{
    color: var(--orange);
    font-size: 66px;
}
/* form>*{
    width: 30rem;
    height: 2rem;
}
.button{
    width: 8rem;
} */

.c-right .user{
    width: 20rem;
    height: 2rem;
    padding: 0.3em;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px;
}
textarea{
    height: 4rem!important;
}
.blur{
    top: 1rem;
    left: 8rem;
}

@media screen and (max-width: 480px) {
    .contact-form{
        padding: 0;
        flex-direction: column;
        gap: 0rem;
        height: 30rem;
        margin-top: -12rem;
    }
    .w-left{
        display: flex;
        flex: 1;
        flex-direction: column;
        text-align: center;
    }
    .awesome>:nth-child(1){
        font-size: 30px;
        display: none;
    }
    .awesome>:nth-child(2){
        font-size: 45px;
        transform: scale(0.8);
        width: 100dvw;
        margin-bottom: 2.5rem;
        margin-left: -3.5rem;
    }

    
    .c-right{
        margin-top: -3rem;
    }
    .c-right .user{
        width: 12rem;
    }

} 