/* Desktop styles */
.menu-navbar {
    height: 10dvh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #A2FF86; */
    box-shadow: #333;
    border-radius: 1.5rem;
    padding: 0 1rem;
    position: sticky;
    top: 0.7rem;
    margin-bottom: 2rem;
    z-index: 999;


  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
  }
  .logo #Logoimg{
    width: 260px;
    height: 120px;
}
  .menu-toggle {
    display: none;
  }
  
  .nav {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  
  .nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-bottom: 0.4rem;
  }
  
  .nav li {
    display: inline-block;
    /* width: 5.5rem;
    text-align: center; */
    margin-right: 20px;
    /* border: 2px solid var(--orange) ; */
    /* padding: 0 4px; */
    border-radius: 5px;
    color: black;
  }
  .nav li:hover {
    color: #E3651D;
  }
  
  .nav a {
    text-decoration: none;
    color: black;
    font-size: 16px;
  }
  
  /* Mobile styles (media query) */
  @media (max-width: 768px) {
    .menu-navbar {
      /* flex-direction: column; */
      display: flex;
      height: 2.5svh;
      gap: 8.5rem;
      padding-top: 1rem;
      /* align-items: center; */
    }
  
    .logo {
      margin-bottom: 20px;
    }
    .logo #Logoimg{
        width: 140px;
        height: inherit;
        margin-left: -4.5rem;
        margin-top: -0.3rem;
    }
  
    .menu-toggle {
      display: block;
      cursor: pointer;
      margin-top: -1rem;
      color: var(--orange);
    }
  
    .nav {
        position: relative;
      display: none;
      margin-left: -16.6rem;
      opacity: 0.95;
      /* height: 50dvh; */
    }
    .nav ul{
        /* display: inline-block; */
        position: absolute;
        /* position: fixed;
        top: 2.5rem; */
        z-index: 999;
        padding-left: 1.4rem;
        padding-top:0.5rem;
        background-color: black;
        /* opacity: 0.92; */
        border-radius: 1rem;
        height: 39svh;
        margin-top: 0.3rem;
        left:  0.8rem;
        
    }
      
  .nav li {
    display: inline-block;
    /* width: 5.5rem;
    text-align: center; */
    margin-right: 20px;
    /* border: 2px solid var(--orange) ; */
    /* padding: 0 4px; */
    border-radius: 5px;
    color: white;
    font-size: 20px;
    /* border-bottom: #E3651D; */
    font-family: "Gill Sans", sans-serif;
    margin-bottom: 1rem;
    border-bottom:3px solid #E3651D;

  }
  
    .nav.active {
      display: block;
    }
    .n-button{
        display: none;
    }
  }
  