.Catigory{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
    margin-bottom: 3rem;
    margin-top: 7rem;
}

.Catigory .cat1{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    height: 50vh;
    gap: 7rem;
    margin-bottom: 2rem;
}
.cat .cat-left{
    width: 45%;
    flex: 6;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    gap: 1rem;
    margin-left: 3rem;
    
}

.cat-left .cat-header{
  margin-top: -3.5rem;
  /* margin-left: 100%; */
  width: 15rem;
  font-size: 50px;
  color: var(--orange);
  font-family: "Gill Sans", sans-serif;
}
 .cat-left .title1{
    color: black;
    font-size: 56px;
    margin-top: -1.5rem;
    
    /* box-shadow: 0 0 18px var(--orange); */
    width: 10rem;
    color: #fca61f;
    border-radius: 0.5rem;
    font-family: "Gill Sans", sans-serif;
}
.cat-left .title2{
    color: white;
    font-size: 42px;
    margin-top: 3rem;
    box-shadow: 0 0 35px #3cff22a4;
    width: 6rem;
    background-color: #3cff22a4;
    font-family: "Gill Sans", sans-serif;
}
.cat-left .desc{
  top: -10rem;
  font-family: "Gill Sans", sans-serif;

}
.cat .cat-left button{
width: 12rem;
height: 2rem;
border-radius: 3rem;
background-color:#E3651D;
color: black;
border: none;
box-shadow: 0 0 50px #777777;
font-family: "Gill Sans", sans-serif;
margin-top: 2rem;
font-size: 1.5rem;

}

.cat .cat-right{
    width: 45%;
    flex: 6;
}

.cat .cat-right img, .img1{
    width: 75%;
    height: 120%;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)
  ;
}
.cat .cat-right  .img1{
    width: 75%;
    height: 450px;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)
  ;
}
.cat-right .swiper {
    width: 300px;
    height: 180px;
  }
  
  .cat-right .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
  
  .cat-right .swiper-slide:nth-child(1n) {
    background-color: #fca61f;
  }
  @media screen and (max-width : 768px) {
    .Catigory{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 17rem;
      width: 100dvw;
      height: 150svh;
    }

    .cat{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: -11rem;
    }
    .cat-left .title1 {
      margin-left: 1rem;
      margin-top: -0.3rem;
    }
    .cat-left  .desc1{
      margin-left: 1rem;
      margin-top: -2.5rem;
    }
    .cat .cat-header ,span{
      font-size: 60px;
      text-align: center;
      margin-left: -1.2rem;
      color: var(--orange);
    }
    #oraBtn{
      display: none;
      margin-top: -1rem;
    }
    .cat-right{
      margin-top: -8rem;
      transform: scale(0.9);
    }
  }